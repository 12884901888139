// taken from: https://docs.google.com/spreadsheets/d/11PT5bj0MenN_kNNdwX2wcjBI_uymAa_JRifikFOuAcY/edit?gid=0#gid=0
const WALLETS: {[coinName: string]: string} = {
    'BTC': '1FyivpoykWewQgooir3W9BUgPjdsebfUZP',
    'ETH': '0xadb4beaee6c703be76cac9d5996ce6ca43c4f485',
    'XRP': 'rJn2zAPdFA193sixJwuFixRkYDUtx3apQh',
    'ADA': 'addr1vxrs2fkapvl9kc08krsqpleepetr3y8jnvmxj2eeuu439rglutx90',
    'SOL': '8do51ySQ4DoxtZH9RkbydgAKtytokjLV6Gzxk8xug3oq',
    'DOT': '1bwV6LopJBEsU2ADJKqP9kEv7QsPspRpBpfqPCS4M4fA7Dm',
    'SHARK': '8do51ySQ4DoxtZH9RkbydgAKtytokjLV6Gzxk8xug3oq'
};

export const getWalletAddressForCoin = (coinName: string): string => {
    const returnableWallet = WALLETS[coinName];
    return returnableWallet ?? WALLETS['BTC'];
};
